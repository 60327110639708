import tren1 from "../assets/img/templates/tem-1.jpg";
import tren2 from "../assets/img/templates/tem-2.png";
import tren3 from "../assets/img/templates/tem-3.jpg";
import tren4 from "../assets/img/templates/tem-4.png";
import tren5 from "../assets/img/templates/tem-5.jpg";
import tren6 from "../assets/img/templates/tem-6.jpg";
import tren7 from "../assets/img/trending/tren-7.jpg";
import tren8 from "../assets/img/trending/tren-8.jpg";
import tren9 from "../assets/img/trending/tren-9.jpg";

import tren24 from "../assets/img/templates/tem-24.png";
import tren25 from "../assets/img/templates/tem-25.jpg";

import trenBig1 from "../assets/img/templates/tem-1-big.jpg";
import trenBig2 from "../assets/img/templates/tem-2-big.png";
import trenBig3 from "../assets/img/templates/tem-3-big.jpg";
import trenBig4 from "../assets/img/templates/tem-4-big.png";
import trenBig5 from "../assets/img/templates/tem-5-big.jpg";
import trenBig6 from "../assets/img/templates/tem-6-big.jpg";
import trenBig7 from "../assets/img/trending/tren-7-big.jpg";
import trenBig8 from "../assets/img/trending/tren-8-big.jpg";
import trenBig9 from "../assets/img/trending/tren-9-big.jpg";

import trenBig24 from "../assets/img/templates/tem-24-big.png";
import trenBig25 from "../assets/img/templates/tem-25-big.jpg";


import prod1 from "../assets/img/product/product-1.jpg";
import prod2 from "../assets/img/product/product-2.jpg";
import prod3 from "../assets/img/product/product-3.jpg";
import prod4 from "../assets/img/product/product-4.jpg";
import prod5 from "../assets/img/product/product-5.jpg";
import prod6 from "../assets/img/product/product-6.jpg";


import icon1 from "../assets/img/product/icon/product-icon-1.png";
import icon2 from "../assets/img/product/icon/product-icon-2.png";
import icon3 from "../assets/img/product/icon/product-icon-3.png";
import icon4 from "../assets/img/product/icon/product-icon-4.png";
import icon5 from "../assets/img/product/icon/product-icon-5.png";
import icon6 from "../assets/img/product/icon/product-icon-6.png";

import prodImg1 from "../assets/img/product/icon/product-img-1.jpg";
import prodImg2 from "../assets/img/product/icon/product-img-2.jpg";
import prodImg3 from "../assets/img/product/icon/product-img-3.jpg";
import prodImg4 from "../assets/img/product/icon/product-img-4.jpg";
import prodImg5 from "../assets/img/product/icon/product-img-5.jpg";
import prodImg6 from "../assets/img/product/icon/product-img-6.jpg";

import logo1 from "../assets/img/product/p-logo/p-logo-1.png";
import logo2 from "../assets/img/product/p-logo/p-logo-2.png";
import logo3 from "../assets/img/product/p-logo/p-logo-3.png";
import logo4 from "../assets/img/product/p-logo/p-logo-4.png";
import logo5 from "../assets/img/product/p-logo/p-logo-5.png";
import logo6 from "../assets/img/product/p-logo/p-logo-6.png";




const productData = [
    {
        id:'1',
        img: tren1,
        img_big: trenBig1,
        title:'Business Consulting',
        category:'Business',
        price:'39',
        url: 'https://zibber.appembryo.com/',
        tag:'portfolio',
    },
    {
        id:'2',
        img: tren2,
        img_big: trenBig2,
        title:'Digital Products Shop',
        category:'E-Commerce',
        price:'25',
        url: 'https://markit.appembryo.com/',
        tag:'construction',
    },
    {
        id:'3',
        img: tren3,
        img_big: trenBig3,
        title:'Digital Marketing',
        category:'Marketing',
        price:'16',
        url: 'https://agency.appembryo.com/',
        tag:'marketplace',
    },
    {
        id:'4',
        img: tren4,
        img_big: trenBig4,
        title:'CommunityNews - full news theme',
        category:'Media & Blog',
        price:'29',
        url: 'https://communitynews.appembryo.com/',
        tag:'portfolio',
    },
    {
        id:'5',
        img: tren5,
        img_big: trenBig5,
        title:'Multipurpose IT Templates',
        category:'Technology',
        price:'44',
        url: 'https://braintech.appembryo.com/',
        tag:'shop',
    },
    {
        id:'6',
        img: tren6,
        img_big: trenBig6,
        title:'Versatile Theme with Professional Design',
        category:'Fintech',
        price:'14',
        url: 'https://zimed.appembryo.com/',
        tag:'responsive',
    },
    {
        id:'7',
        img: tren7,
        img_big: trenBig7,
        title:'Multipurpose Store',
        category:'Healthcare',
        price:'55',
        url: 'https://zibber.appembryo.com/',
        tag:'multipurpose',
    },
    {
        id:'8',
        img: tren8,
        img_big: trenBig8,
        title:'Responsive Agency',
        category:'Education',
        price:'22',
        url: 'https://zibber.appembryo.com/',
        tag:'responsive',
    },
    {
        id:'9',
        img: tren9,
        img_big: trenBig9,
        title:'Coomunity Development',
        category:'Non-for-profit',
        price:'49',
        url: 'https://zibber.appembryo.com/',
        tag:'NGO',
    },


    {
        id:'10',
        img: prod1,
        img_big: prod1,
        title:'Zibber - Business Consulting',
        category:'Construction',
        price:'69',
        url: 'https://zibber.appembryo.com/',
        tag:'agency',
    },
    

    //next round with the same repeating categories, start from id #21

    {
        id:'21',
        img: tren1,
        img_big: trenBig1,
        title:'Business Consulting',
        category:'Business',
        price:'39',
        url: 'https://zibber.appembryo.com/',
        tag:'portfolio',
    },
    {
        id:'22',
        img: tren2,
        img_big: trenBig2,
        title:'Digital Products Shop',
        category:'E-Commerce',
        price:'25',
        url: 'https://markit.appembryo.com/',
        tag:'construction',
    },
    {
        id:'23',
        img: tren3,
        img_big: trenBig3,
        title:'Digital Marketing',
        category:'Marketing',
        price:'16',
        url: 'https://zibber.appembryo.com/',
        tag:'marketplace',
    },
    {
        id:'24',
        img: tren24,
        img_big: trenBig24,
        title:'Advise&Resources Blog',
        category:'Media & Blog',
        price:'29',
        url: 'https://klaud.appembryo.com/',
        tag:'portfolio',
    },
    {
        id:'25',
        img: tren25,
        img_big: trenBig25,
        title:'Hosting Service Theme',
        category:'Technology',
        price:'44',
        url: 'https://braintech.appembryo.com/',
        tag:'hosting',
    },
    {
        id:'26',
        img: tren6,
        img_big: trenBig6,
        title:'Versatile Theme with Professional Design',
        category:'Fintech',
        price:'14',
        url: 'https://zimed.appembryo.com/',
        tag:'responsive',
    },
    {
        id:'27',
        img: tren7,
        img_big: trenBig7,
        title:'Multipurpose Store',
        category:'Healthcare',
        price:'55',
        url: 'https://zibber.appembryo.com/',
        tag:'multipurpose',
    },
    {
        id:'28',
        img: tren8,
        img_big: trenBig8,
        title:'Responsive Agency',
        category:'Education',
        price:'22',
        url: 'https://zibber.appembryo.com/',
        tag:'responsive',
    },
    {
        id:'29',
        img: tren9,
        img_big: trenBig9,
        title:'Coomunity Development',
        category:'Non-for-profit',
        price:'49',
        url: 'https://zibber.appembryo.com/',
        tag:'NGO',
    },

    {
        id:'30',
        img: prod1,
        img_big: prod1,
        title:'Zibber - Business Consulting',
        category:'Construction',
        price:'69',
        url: 'https://zibber.appembryo.com/',
        tag:'agency',
    },









    //----------------------------------------
    {
        id:'61',
        img: prod2,
        img_big: prod2,
        category:'Business',
        price:'23',
        title:'Pixen - Printing Services Company',
        sale:'21',
        url: 'https://zibber.appembryo.com/',
        tag:'Business',
    },
    {
        id:'62',
        img: prod2,
        img_big: prod2,
        category:'E-Commerce',
        price:'23',
        title:'Pixen - Printing Services Company',
        sale:'21',
        url: 'https://zibber.appembryo.com/',
        tag:'e-Commerce',
    },
    {
        id:'63',
        img: prod3,
        img_big: prod3,
        category:'Marketing',
        price:'12',
        title:'Findup - Directory & Listing PSD',
        sale:'2',
        url: 'https://zibber.appembryo.com/',
        tag:'marketplace',
    },
    {
        id:'64',
        img: prod4,
        img_big: prod4,
        category:'Media & Blog',
        price:'59',
        title:'CommunityNews - a professional ..',
        sale:'48',
        url: 'https://communitynews.appembryo.com/',
        tag:'shop',
    },
    {
        id:'65',
        img: prod5,
        img_big: prod5,
        category:'Technology',
        price:'49',
        title:'BngTech - IT Solutions WordPres',
        sale:'14',
        url: 'https://zibber.appembryo.com/',
        tag:'construction',
    },
    {
        id:'66',
        img: prod6,
        img_big: prod6,
        category:'Fintech',
        price:'29',
        title:'BildPress - Construction WordPres',
        sale:'41',
        url: 'https://zibber.appembryo.com/',
        tag:'shop',
    },


    {
        id:'67',
        img: icon1,
        img_big: prodImg1,
        category:'Healthcare',
        logo: logo1,
        gradientColor:"gradient-pink-2",
        title:'Appshow Plugin',
        price:'44',
        url: 'https://zibber.appembryo.com/',
        tag:'portfolio',
    },
    {
        id:'68',
        img: icon2,
        img_big: prodImg2,
        category:'Education',
        logo: logo2,
        title:'Elite Licenser',
        gradientColor:"gradient-yellow",
        price:'34',
        url: 'https://zibber.appembryo.com/',
        tag:'marketplace',
    },
    {
        id:'69',
        img: icon3,
        img_big: prodImg3,
        category:'Non-for-profit',
        logo: logo3,
        title:'Mailchack Plugin',
        gradientColor:"gradient-blue-2",
        price:'48',
        url: 'https://zibber.appembryo.com/',
        tag:'responsive',
    },
    {
        id:'30',
        img: icon4,
        img_big: prodImg4,
        category:'Construction',
        logo: logo4,
        title:'MC Builder',
        gradientColor:"gradient-blue-2",
        price:'52',
        url: 'https://zibber.appembryo.com/',
        tag:'education',
    },

    //next round with the same repeating categories, start from id #1
    
    //.......
    {
        id:'49',
        img: icon5,
        img_big: prodImg5,
        category:'Non-for-profit',
        logo: logo5,
        title:'Mailchack Plugin',
        gradientColor:"gradient-blue-2",
        price:'48',
        url: 'https://zibber.appembryo.com/',
        tag:'responsive',
    },
    {
        id:'50',
        img: icon6,
        img_big: prodImg6,
        category:'Construction',
        logo: logo6,
        title:'MC Builder',
        gradientColor:"gradient-blue-2",
        price:'52',
        url: 'https://zibber.appembryo.com/',
        tag:'education',
    },
]

export default productData;