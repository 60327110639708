import blog1 from "../assets/img/blog/blog-1.jpg";
import blog2 from "../assets/img/blog/blog-2.jpg";
import blog3 from "../assets/img/blog/blog-3.jpg";
import blog4 from "../assets/img/blog/blog-4.jpg";
import blog5 from "../assets/img/blog/blog-5.jpg";
import blog6 from "../assets/img/blog/blog-6.jpg";

import BlogAuthor1 from "../assets/img/blog/author/blog-author-1.jpg";
import BlogAuthor2 from "../assets/img/blog/author/blog-author-2.jpg";
import BlogAuthor3 from "../assets/img/blog/author/blog-author-3.jpg";
import BlogAuthor4 from "../assets/img/blog/author/blog-author-4.jpg";
import BlogAuthor5 from "../assets/img/blog/author/blog-author-5.jpg";
import BlogAuthor6 from "../assets/img/blog/author/blog-author-6.jpg";

import smImage1 from "../assets/img/blog/sm/blog-sm-1.jpg";
import smImage2 from "../assets/img/blog/sm/blog-sm-2.jpg";
import smImage3 from "../assets/img/blog/sm/blog-sm-3.jpg";


const blogData = [
    {
        id:'1',
        img: blog1,
        category:'Marketing',
        title:'Why we decide to build a collaborative UX design tools platform?',
        date:'October 15, 2021',
        desc:'The little rotter absolutely bladdered wind up victoria sponge starkers cack posh jolly good lost the plot nancy boy bonnet plastered.',
        name:'Justin Case',
        user_img: BlogAuthor1,
    },
    {
        id:'2',
        img: blog2,
        category:'Business',
        title:'Marketplace SEO: Tips for Etsy, AppEmbryo & Amazon Handmade',
        date:'December 24, 2021',
        videoPopup: true,
        desc:'The little rotter absolutely bladdered wind up victoria sponge starkers cack posh jolly good lost the plot nancy boy bonnet plastered.',
        name:'Jackson Pot',
        user_img: BlogAuthor2,
        sm_img: smImage1,
    },
    {
        id:'3',
        img: blog3,
        category:'Landing',
        title:'How to get your handmade website found on Google',
        date:'April 13, 2021',
        desc:'The little rotter absolutely bladdered wind up victoria sponge starkers cack posh jolly good lost the plot nancy boy bonnet plastered.',
        name:'Weir Doe',
        user_img: BlogAuthor3,
        sm_img: smImage2,
    },
    {
        id:'4',
        img: blog4,
        category:'Technology',
        title:'AppEmbryo is changing Our biggest announcement Ever!',
        date:'March 24, 2021',
        desc:'The little rotter absolutely bladdered wind up victoria sponge starkers cack posh jolly good lost the plot nancy boy bonnet plastered.',
        name:'Shahnewaz Sakil',
        user_img: BlogAuthor4,
        sm_img: smImage3,
    },
    {
        id:'5',
        img: blog5,
        category:'Agency',
        title:'Why we decide to build a collaborative UX design tools platform?',
        date:'January 24, 2022',
        desc:'The little rotter absolutely bladdered wind up victoria sponge starkers cack posh jolly good lost the plot nancy boy bonnet plastered.',
        name:'Weir Doe',
        user_img: BlogAuthor5,
    },
    {
        id:'6',
        img: blog6,
        category:'Landing',
        title:'Marketplace SEO: Tips for Etsy, AppEmbryo & Amazon Handmade',
        date:'February 10, 2022',
        desc:'The little rotter absolutely bladdered wind up victoria sponge starkers cack posh jolly good lost the plot nancy boy bonnet plastered.',
        name:'Jackson Pot',
        user_img: BlogAuthor6,
    },
]

export default blogData;