import { Link } from 'react-router-dom';
import React from 'react';

import services1 from '../../assets/img/icon/services/services-1.png';
import services2 from '../../assets/img/icon/services/services-2.png';
import services3 from '../../assets/img/icon/services/services-3.png';
import services4 from '../../assets/img/icon/services/services-4.png';
import services5 from '../../assets/img/icon/services/services-5.png';

//import url from `../../assets/img/icon/services/services-(${img}).svg`

const url = [

]

const SingleService = ({ color, img, title }) => {
   return (
      <>
         <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
            <div className="services__item white-bg mb-30 wow fadeinup" data-wow-delay=".3s">
               <div className="services__icon mb-45">
                  <span className={color}><img src={img} alt="" /></span>
               </div>
               <div className="services__content">
                  {title}
                  <p>Haggle down the pub off his nut arse bog bits and bobs bugger.</p>
                  <Link to="/about">
                     <a className="link-btn"><i className="fas fa-long-arrow-alt-right"></i>Learn More</a>
                  </Link>
               </div>
            </div>
         </div>
      </>
   );
};

export default SingleService;