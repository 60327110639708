import { Link } from 'react-router-dom';
import React from 'react';

import banner1 from '../../assets/img/banner/banner-1.jpg';
import banner2 from '../../assets/img/banner/banner-2.jpg';

const BannerArea = () => {
   return (
      <>
         <section className="banner__area pb-90">
            <div className="container">
               <div className="row">
                  <div className="col-xxl-6 col-xl-6 col-md-6">
                     <div className="banner__item mb-30 wow fadeInUp" data-wow-delay=".3s" style={{background:`url(${banner1}) center/cover no-repeat`}}>
                     <h3 className="banner__title">Designers who changed the web with AppEmbryo</h3>
                        <p>We can include any component of the themes you find here to your dream website, can combine any elements from various themes.</p>
                        <Link to="https://www.appembryo.com/about" target="_blank" rel="noopener noreferrer"><a  className="m-btn m-btn-white banner__more"> <span></span> Learn More</a></Link>
                     </div>
                  </div>
                  <div className="col-xxl-6 col-xl-6 col-md-6">
                     <div className="banner__item mb-30 wow fadeInUp" data-wow-delay=".7s" style={{background:`url(${banner2}) center/cover no-repeat`}}>
                        <h3 className="banner__title">Professional Websites are made by AppEmbryo</h3>
                        <p>You find the best theme designs with us. People contacting us for website has never gone to other agencies.</p>
                        <Link to="https://www.appembryo.com/about" target="_blank" rel="noopener noreferrer"><a  className="m-btn m-btn-white banner__more"> <span></span> Learn More</a></Link>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default BannerArea;