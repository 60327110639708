import { BrowserRouter, Routes, Route } from "react-router-dom";
//import ScrollTop from "./components/ScrollTop";
import About from "./pages/about";
import Blogs from "./pages/blog";
import BlogDetails from "./pages/blog-details";
import Cart from "./pages/cart";
import Checkout from "./pages/checkout";
import Contact from "./pages/contact";
import Documentation from "./pages/documentation";
import Home from './pages/home';
import Home1 from './pages/home';
import Home2 from "./pages/home-two";
import Home3 from "./pages/home-three";
import Login from "./pages/login";
import Pricing from "./pages/pricing";
import Product from "./pages/product";
import ProductDetails from "./pages/product-details";
import SignUp from "./pages/sign-up";
import Support from "./pages/support";
import WishList from "./pages/wishlist";


function App() {
  return (
    <>
      <BrowserRouter>
        {/* <ScrollTop/> */}
        <Routes>
            <Route path="/" element={<Home/>} />
            <Route path="/home" element={<Home1/>} />
            <Route path="/home-two" element={<Home2/>} />
            <Route path="/home-three" element={<Home3/>} />
            <Route path="/about" element={<About/>} />
            <Route path="/blog" element={<Blogs/>} />
            <Route path="/blog-details" element={<BlogDetails/>} />
            <Route path="/cart" element={<Cart/>} />
            <Route path="/checkout" element={<Checkout/>} />
            <Route path="/contact" element={<Contact/>} />
            <Route path="/documentation" element={<Documentation/>} />
            <Route path="/login" element={<Login/>} />
            <Route path="/pricing" element={<Pricing/>} />
            <Route path="/product" element={<Product/>} />
            <Route path="/product-details" element={<ProductDetails/>} />
            <Route path="/sign-up" element={<SignUp/>} />
            <Route path="/support" element={<Support/>} />
            <Route path="/wishList" element={<WishList/>} />
            
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
