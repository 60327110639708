import React from 'react';

import Bgimage from '../../assets/img/shape/shape-1.png';

const BgShape = () => {
    return (
        <>
            <div className="bg-shape">
                <img src={Bgimage} alt="Bgimage" />
            </div>
        </>
    );
};

export default BgShape;