import { Link } from 'react-router-dom';
import React, { useState } from 'react';
// import useAuth from '../../hooks/useAuth';
import useSticky from '../../hooks/useSticky';
import SidebarMenu from '../Sidebar/SidebarMenu';

import LogoWhite from '../../assets/img/logo/logo-white.png';

const HomeThreeHeader = () => {
   // sticky
   const { sticky } = useSticky();
   // handle sidebar show
   const [show, setShow] = useState(false);
   // handle close
   const handleClose = () => setShow(false);
   // handle sidebar show
   const handleShow = () => setShow(true);

   // //   user
   // const { user, logout } = useAuth();
   return (
      <>
         <header>
            <div className={sticky ? "sticky header__area header__shadow-2 white-bg header-transparent" :
               "header__area header__shadow-2 white-bg header-transparent"} id="header-sticky">
               <div className="container">
                  <div className="row align-items-center">
                     <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-4 col-6">
                        <div className="logo">
                           <Link to="/">
                              <a >
                                 <img src={LogoWhite} alt="logo" />
                              </a>
                           </Link>
                        </div>
                     </div>
                     <div className="col-xxl-7 col-xl-7 col-lg-7 d-none d-lg-block">
                        <div className="main-menu d-flex justify-content-end h3_menu">
                           <nav id="mobile-menu">
                              <ul>
                                 <li className="has-dropdown active">
                                    <Link to="/"><a >Home</a></Link>
                                    <ul className="submenu">
                                       <li><Link to="/"><a >Home 1</a></Link></li>
                                       <li><Link to="/home-two"><a >Home 2</a></Link></li>
                                       <li><Link to="/home-three"><a >Home 3</a></Link></li>
                                    </ul>
                                 </li>
                                 <li className="has-dropdown">
                                    <Link to="/product">
                                       <a >Themes</a>
                                    </Link>
                                    <ul className="submenu">
                                       <li><Link to="/product"><a>Product</a></Link></li>
                                       <li><Link to="/product-details"><a>Product Details</a></Link></li>
                                    </ul>
                                 </li>
                                 <li><Link to="/product"><a>HTML</a></Link></li>
                                 <li><Link to="/support"><a>Support</a></Link></li>
                                 <li className="has-dropdown">
                                    <Link to="/product">
                                       <a >pages</a>
                                    </Link>
                                    <ul className="submenu">
                                       <li><Link to="/about"><a >About</a></Link></li>
                                       <li><Link to="/documentation"><a >Documentation</a></Link></li>
                                       <li><Link to="/cart"><a >Cart Page</a></Link></li>
                                       <li><Link to="/wishlist"><a >Wishlist</a></Link></li>
                                       <li><Link to="/checkout"><a >Checkout</a></Link></li>
                                       <li><Link to="/pricing"><a >Pricing</a></Link></li>
                                       <li><Link to="/sign-up"><a >Sign Up</a></Link></li>
                                       <li><Link to="/login"><a >Log In</a></Link></li>
                                    </ul>
                                 </li>
                                 <li className="has-dropdown">
                                    <Link to="/blog"><a >Blog</a></Link>

                                    <ul className="submenu">
                                       <li><Link to="/blog"><a >Blog</a></Link></li>
                                       <li><Link to="/blog-details"><a >Blog Details</a></Link></li>
                                    </ul>
                                 </li>
                                 <li><Link to="/contact"><a >Contact</a></Link></li>
                              </ul>
                           </nav>
                        </div>
                     </div>
                     <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-8 col-6">
                        <div className="header__action d-flex align-items-center justify-content-end">
                           <div className="header__login header__login-2 d-none d-sm-block">
                              {/* {user?.email ? < a onClick={logout} style={{ cursor: 'pointer' }} className='d-flex align-items-center'> <i className="fal fa-sign-out-alt"></i> Log Out</a> : */}
                                 <Link to="/login">
                                    < a> <i className="far fa-unlock"></i> Log In</a>
                                 </Link>
                              {/* } */}
                           </div>
                           <div className="header__btn d-none d-xl-block">
                              <Link to="/contact">
                                 <a className="m-btn m-btn-3">get started</a>
                              </Link>
                           </div>
                           <div className="sidebar__menu d-lg-none" onClick={handleShow}>
                              <div className="sidebar-toggle-btn" id="sidebar-toggle">
                                 <span className="line"></span>
                                 <span className="line"></span>
                                 <span className="line"></span>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </header>


         <SidebarMenu show={show} handleClose={handleClose} />
      </>
   );
};

export default HomeThreeHeader;