import React from 'react';

import map from '../../assets/img/shape/map.png';

import com1 from '../../assets/img/community/com-1.jpg';
import com2 from '../../assets/img/community/com-2.jpg';
import com3 from '../../assets/img/community/com-3.jpg';
import com4 from '../../assets/img/community/com-4.jpg';
import com5 from '../../assets/img/community/com-5.jpg';
import com6 from '../../assets/img/community/com-6.jpg';
import com7 from '../../assets/img/community/com-7.jpg';

const Community = () => {
   return (
      <>
         <div className="community__area pb-140">
            <div className="container">
               <div className="row">
                  <div className="col-xxl-10 offset-xxl-1">
                     <div className="community__wrapper p-relative wow fadeInUp" data-wow-delay=".7s">
                        <img className="community-map" src={map} alt="" />
                        <div className="community__person">
                           <img className="com-1" src={com1} alt="" />
                           <img className="com-2" src={com2} alt="" />
                           <img className="com-3" src={com3} alt="" />
                           <img className="com-4" src={com4} alt="" />
                           <img className="com-5" src={com5} alt="" />
                           <img className="com-6" src={com6} alt="" />
                           <img className="com-7" src={com7} alt="" />
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </>
   );
};

export default Community;