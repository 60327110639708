import { Link } from 'react-router-dom';
import React, { useState } from 'react';
//import { useSelector } from 'react-redux';
import useCartInfo from '../../hooks/use-cart-info';
//import useAuth from '../../hooks/useAuth';
import useSticky from '../../hooks/useSticky';
import SidebarMenu from '../Sidebar/SidebarMenu';

import Logo from '../../assets/img/logo/logo.png';

const Header = () => {
   // handle cartQuantity
   const {quantity} = useCartInfo();
   // handle sidebar show
   const [show, setShow] = useState(false);
   // handle close
   const handleClose = () => setShow(false);
   // handle sidebar show
   const handleShow = () => setShow(true);
   // sticky nav
   const { sticky } = useSticky();
   // user
   //const { user, logout } = useAuth();
   return (
      <>
         <header>
            <div className={sticky ? "sticky header__area white-bg" : "header__area white-bg"} id="header-sticky">
               <div className="container">
                  <div className="row align-items-center">
                     <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-4 col-6">
                        <div className="logo">
                           <Link to="/">
                              <a>
                                 <img src={Logo} alt="logo" />
                              </a>
                           </Link>
                        </div>
                     </div>
                     <div className="col-xxl-7 col-xl-7 col-lg-7 d-none d-lg-block">
                        <div className="main-menu d-flex justify-content-end">
                           <nav id="mobile-menu">
                              <ul>
                                 <li className="active">
                                    <a href="https://www.appembryo.com/" target="_blank" rel="noopener noreferrer">Home</a>
                                    {/* <ul className="submenu">
                                       <li><Link to="/home"><a>Home 1</a></Link></li>
                                       <li><Link to="/home-two"><a>Home 2</a></Link></li>
                                       <li><Link to="/home-three"><a>Home 3</a></Link></li>
                                    </ul> */}
                                 </li>
                                 {/* <li className="has-dropdown">
                                    <Link to="/product">
                                       <a>Themes</a>
                                    </Link>
                                    <ul className="submenu">
                                       <li><Link to="/product"><a>Product</a></Link></li>
                                       <li><Link to="/product-details"><a>Product Details</a></Link></li>
                                    </ul>
                                 </li>
                                 <li><Link to="/product"><a>HTML</a></Link></li>
                                 <li><Link to="/support"><a>Support</a></Link></li>

                                 <li className="has-dropdown">
                                    <Link to="/product">
                                       <a>pages</a>
                                    </Link>
                                    <ul className="submenu">
                                       <li><Link to="/about"><a>About</a></Link></li>
                                       <li><Link to="/documentation"><a>Documentation</a></Link></li>
                                       <li><Link to="/cart"><a>Cart Page</a></Link></li>
                                       <li><Link to="/wishlist"><a>Wishlist</a></Link></li>
                                       <li><Link to="/checkout"><a>Checkout</a></Link></li>
                                       <li><Link to="/pricing"><a>Pricing</a></Link></li>
                                       <li><Link to="/sign-up"><a>Sign Up</a></Link></li>
                                       <li><Link to="/login"><a>Log In</a></Link></li>
                                    </ul>
                                 </li>
                                 <li className="has-dropdown">
                                    <Link to="/blog"><a>Blog</a></Link>

                                    <ul className="submenu">
                                       <li><Link to="/blog"><a>Blog</a></Link></li>
                                       <li><Link to="/blog-details"><a>Blog Details</a></Link></li>
                                    </ul>
                                 </li> */}
                                 <li><a href="https://www.appembryo.com/contact" target="_blank" rel="noopener noreferrer">Contact</a></li>
                              </ul>
                           </nav>
                        </div>
                     </div>
                     <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-8 col-6">
                        <div className="header__action d-flex align-items-center justify-content-end">
                           <div className="header__login d-lg-flex align-items-center d-none d-sm-block">
                              {/* {user?.email ? < a onClick={logout} style={{ cursor: 'pointer' }} className='d-flex align-items-center'> <i className="fal fa-sign-out-alt"></i> Log Out</a> : */}
                                 <Link to="#">
                                    < a> <i className="far fa-unlock"></i> Log In</a>
                                 </Link>
                              {/* } */}
                           </div>
                           {/* <div className="header__cart d-none d-sm-block">
                              <Link to="/cart">
                                 <a className="cart-toggle-btn">
                                    <i className="far fa-shopping-cart"></i>
                                    <span>{quantity}</span>
                                 </a>
                              </Link>
                           </div> */}
                           <div className="sidebar__menu d-lg-none" onClick={handleShow}>
                              <div className="sidebar-toggle-btn" id="sidebar-toggle">
                                 <span className="line"></span>
                                 <span className="line"></span>
                                 <span className="line"></span>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </header>


         <SidebarMenu show={show} handleClose={handleClose} />
      </>
   );
};

export default Header;