import { Link } from 'react-router-dom';
import React from 'react';
import SingleService from './SingleService';

import services1 from '../../assets/img/icon/services/services-1.png';
import services2 from '../../assets/img/icon/services/services-2.png';
import services3 from '../../assets/img/icon/services/services-3.png';
import services4 from '../../assets/img/icon/services/services-4.png';
import services5 from '../../assets/img/icon/services/services-5.png';

const Services = () => {
   return (
      <>
         <section className="services__area pb-110">
            <div className="container">
               <div className="row">
                  <div className="col-xxl-12">
                     <div className="section__title-wrapper mb-50 text-center">
                        <h2 className="section__title">The only one <br />Template you need</h2>
                        <p>From multipurpose themes to niche templates</p>
                     </div>
                  </div>
               </div>
               <div className="row">

                  <SingleService color="blue-bg-4" img={services1} title={<h3 className="services__title"><a href="#">No Risk,
                     <br /> Double Guarantee</a></h3>} />
                     
                  <SingleService color="pink-bg" img={services2} title={<h3 className="services__title">
                     <Link to="/about">
                     <a >Flexible <br /> Prices no Surprises</a></Link></h3>} />

                  <SingleService color="green-bg" img={services3} title={<h3 className="services__title">
                     <Link to="/about">
                     <a >Graphic <br /> Design Services</a></Link></h3>} />

                  <SingleService color="orange-bg" img={services4} title={<h3 className="services__title">
                     <Link to="/about"><a >Updates <br /> Constant Development</a></Link></h3>} />
                  <SingleService color="blue-bg" img={services5} title={<h3 className="services__title">
                     <Link to="/about">
                     <a >Friendly <br />Customer Support</a></Link></h3>} />

               </div>
            </div>
         </section>
      </>
   );
};

export default Services;