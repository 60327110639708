import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '../common/Pagination';
import { addToProduct, specificItem } from '../../redux/features/productSlice';

//import productData from "../../data/productData";

import banner from '../../assets/img/banner/sidebar-banner.jpg';

const Product = () => {
  // all products
  // let products = useSelector(state => state.products.products);
  const [products, setProducts] = useState(useSelector(state => state.products.products));
  const [filterProducts, setFilterProducts] = useState(useSelector(state => state.products.products));
  const [uniqueTag, setUniqueTag] = useState(useSelector(state => state.products.allUniqueTag));
  const [checkbox, setCheckBox] = useState('');
  const [categoryTag, setCategoryTag] = useState('');
  // unique category
  const productsCategory = useSelector(state => state.products.allUniqueCategory);
  // dispaych
  const dispatch = useDispatch();
  // currentpage
  const [currentPage, setCurrentPage] = useState(1);
  // product per page
  const [productPerPage, setProductPerPage] = useState(6);
  // index of last product
  const indexOfLastProduct = currentPage * productPerPage;
  // index of first product
  const indexOfFirstProduct = indexOfLastProduct - productPerPage;
  // current products
  let currentProducts = filterProducts.slice(checkbox ? 0 : indexOfFirstProduct, checkbox ? filterProducts.length : indexOfLastProduct);
  // paginate
  const paginate = (number) => {
    setCurrentPage(number);
  };
  // handleClick
  const handleClick = (product) => {
    dispatch(addToProduct(product))
  }
  // handleProducDetails
  const handleProducDetails = (id) => {
    dispatch(specificItem(id))
  }
  // handleCategory
  const handleCategory = (category) => {
    setCheckBox(category)
    const newProduct = products.filter(product => product.category === category);
    setFilterProducts(newProduct);
  }
  // handleTag
  const handleTag = (tag) => {
    setCheckBox(tag);
    const newProduct = products.filter(product => product.tag === tag);
    setFilterProducts(newProduct)
  }

  // // search functioning tems
  // const [NFTList, setNFTList] = useState(expolreNow);

  // const searchNFT = () => {
  //   var searchProductList = document.getElementById("searchProductList");
  //   var inputVal = searchProductList.value.toLowerCase();
  //   function filterItems(arr, query) {
  //       return arr.filter(function (el) {
  //           return el.title.toLowerCase().indexOf(query.toLowerCase()) !== -1;
  //       });
  //   }
  //   var filterData = filterItems(expolreNow, inputVal);
  //   if (filterData.length === 0) {
  //       document.getElementById("noresult").style.display = "block";
  //       document.getElementById("loadmore").style.display = "none";
  //   } else {
  //       document.getElementById("noresult").style.display = "none";
  //       document.getElementById("loadmore").style.display = "block";
  //   }
  //   setNFTList(filterData);
  // };

  return (
    <>

      <section id="product" className="product__area po-rel-z1 pt-150 pb-115 grey-bg">
        <div className="container temp">
          <div className="row">
            <div className="col d-flex justify-content-center mb-20">
              <div className="hero__search">
                <form action="#">
                  <div className="hero__search-inner d-xl-flex">
                      <div className="hero__search-input z-5">
                        <span><i className="far fa-search"></i></span>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search for templates"
                          autoComplete="off"
                          id="searchProductList"
                          //onKeyUp={searchTemplate}
                        />
                      </div>
                      <button type="submit" className="m-btn ml-20"> <span></span> search</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-3 col-xl-3 col-lg-3 order-lg-first order-first">
              <div className="product__sidebar mr-30">
                <div className="product__sidebar-widget  white-bg mb-30">
                  <div className="sidebar__widget mb-20">
                    <form>
                      <div className="sidebar__widget-head d-flex align-items-center justify-content-between">
                        <h4 className="sidebar__widget-title">Category</h4>
                        <button type="submit" className="sidebar__clear-btn"><i className="fal fa-repeat"></i>Clear</button>
                      </div>
                      <div className="sidebar__widget-content">
                        <div className="sidebar__check-wrapper sidebar__tag">
                          <ul>
                            {
                              productsCategory.map((category, index) => {
                                return <li key={index} className="d-flex justify-content-between align-items-center">
                                  {category !== undefined && <>
                                    <div className="sidebar__check d-flex align-items-center">
                                      <span className='d-flex align-items-center'
                                        onClick={() => handleCategory(category)} >
                                        <input className="m-check-input" type="checkbox" readOnly
                                          checked={checkbox === category ? 'checked' : ''} />
                                        <label className="m-check-label">  {category} </label>
                                      </span>
                                    </div>
                                    {/* <span>76</span> */}
                                  </>
                                  }
                                </li>
                              })
                            }
                          </ul>

                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="sidebar__widget">
                    <form>
                      <div className="sidebar__widget-head d-flex align-items-center justify-content-between">
                        <h4 className="sidebar__widget-title">Tags</h4>
                        <button type="submit" className="sidebar__clear-btn"><i className="fal fa-repeat"></i>Clear</button>
                      </div>
                      <div className="sidebar__widget-content">
                        <div className="sidebar__check-wrapper sidebar__tag">
                          <ul>
                            {
                              uniqueTag.map((tag, index) => {
                                return <li key={index} className="d-flex justify-content-between align-items-center">
                                  <div className="sidebar__check d-flex align-items-center text-capitalize">
                                    {/* <input className="m-check-input" type="checkbox"  />
                                    <label className="m-check-label">{tag}</label> */}
                                    <span className='d-flex align-items-center'
                                      onClick={() => handleTag(tag)} >
                                      <input className="m-check-input" type="checkbox" readOnly
                                        checked={checkbox === tag ? 'checked' : ''} />
                                      <label className="m-check-label">  {tag} </label>
                                    </span>
                                  </div>
                                  {/* <span>28</span> */}
                                </li>
                              })
                            }

                          </ul>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="sidebar__banner" style={{background:`url(${banner}) center/cover no-repeat`}} >
                  <h4 className="sidebar__banner-title">Let Us Consult <br />Your Favorite Design !!</h4>
                  <a className="m-btn m-btn-white" href="https://www.appembryo.com/contact" target="_blank" rel="noopener noreferrer"> <span></span> Get Started</a>
                </div>
              </div>
            </div>

            <div className="col-xxl-9 col-xl-9 col-lg-9">
              <div className="row">

                {
                  currentProducts.map((item, index) => {
                    return <div key={index} className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                      <div className="product__item white-bg mb-30 wow fadeInUp" data-wow-delay=".3s">
                        <div className="product__thumb">
                          <div className="product__thumb-inner fix w-img">
                            <Link to="javascript:void(0)">  {/* "/product-details" */}
                              <a >
                                <img src={item?.img_big} alt="" />
                              </a>
                            </Link>
                          </div>
                          <div className="product__thumb-btn transition-3">
                        
                              {/* <a style={{cursor:'pointer'}} onClick={() => handleClick(item)} className="m-btn m-btn-6 mb-15">
                                Add To Cart
                              </a> */}
                            <button className="m-btn m-btn-7">
                              <a rel="noopener" href={item?.url} target="_blank" >
                                Preview Project </a>
                            </button>
                          </div>
                        </div>
                        <div className="product__content">
                          <div className="product__meta mb-10 d-flex justify-content-between align-items-center">
                            <div className="product__tag">
                              <button onClick={() => handleCategory(item?.category)}>
                                <a>{item?.category}</a></button>
                            </div>
                            {/* <div className="product__price">
                              <span>${item?.price}</span>
                            </div> */}
                          </div>
                          <h3 onClick={() => handleProducDetails(item.id)} className="product__title">
                            <a rel="noopener" href={item?.url} target="_blank" >{item?.title}...</a>
                          </h3>
                          <p className="product__author">by <a href="#">AppEmbryo</a> in <a href="#">Templates</a></p>
                        </div>
                      </div>
                    </div>
                  })
                }


              </div>

              {!checkbox && <div className="row">
                <Pagination productPerPage={productPerPage} totalProduct={filterProducts.length}
                  paginate={paginate} currentPage={currentPage} />
              </div>}

            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Product;