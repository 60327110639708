import React from 'react';

import subscribeBg from '../../assets/img/bg/subscribe-bg.jpg';

import Ps from '../../assets/img/icon/subscribe/ps.png';
import Wp from '../../assets/img/icon/subscribe/wp.png';
import Html from '../../assets/img/icon/subscribe/html.png';
import Fb from '../../assets/img/icon/subscribe/f.png';
import man from '../../assets/img/icon/subscribe/man.png';

const Subscribe = () => {
   return (
      <>
         <section className="subscribe__area p-relative pt-100 pb-110" style={{background:`url(${subscribeBg}) center/cover no-repeat`}}>
            <div className="subscribe__icon">
               <img className="ps" src={Ps} alt="" />
               <img className="wp" src={Wp} alt="" />
               <img className="html" src={Html} alt="" />
               <img className="f" src={Fb} alt="" />
               <img className="man" src={man} alt="" />
            </div>
            <div className="container">
               <div className="row">
                  <div className="col-xxl-12">
                     <div className="subscribe__content text-center wow fadeInUp" data-wow-delay=".5s">
                        <h3 className="subscribe__title">Have a project? <br /> Create your website now.</h3>
                        <p>Try our any product for FREE!</p>
                        <div className="subscribe__form wow fadeInUp" data-wow-delay=".7s">
                           <form action="#">
                              <input type="email" placeholder="Email Address" />
                              <button type="submit" className="m-btn m-btn-black"><span></span> subscribe </button>
                           </form>
                           <p>Join 2,000+ other members in our AppEmbryo community.</p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default Subscribe;