import { Link } from 'react-router-dom';

import Cat1 from '../../assets/img/icon/catagory/cat-1.png';
import Cat2 from '../../assets/img/icon/catagory/cat-2.png';
import Cat3 from '../../assets/img/icon/catagory/cat-3.png';
import Cat4 from '../../assets/img/icon/catagory/cat-4.png';


const categoryData = [
   {
      id: 1,
      img: Cat1,
      title: 'AppEmbryo Themes'
   },
   {
      id: 2,
      img: Cat2,
      title: 'Site Templates'
   },
   {
      id: 3,
      img: Cat3,
      title: 'UI Templates'
   },
   {
      id: 4,
      img: Cat4,
      title: 'Digital Marketing'
   },
]

const CategoryArea = () => {

   const handleClick = () => {
      const element = document.getElementById('product');
      if (element) {
         element.scrollIntoView({behavior: 'smooth', inline: "nearest"})
      }
   };

   return (
      <>
         <section className="category__area pt-105 pb-135">
            <div className="container">
               <div className="row">
                  <div className="col-xxl-12">
                     <div className="section__title-wrapper text-center mb-60">
                        <h2 className="section__title">Browse Our Main <br /> <span>Development Areas</span></h2>
                        <p>Find over 700 website templates and themes.</p>
                     </div>
                  </div>
               </div>
               <div className="row">

                  {
                     categoryData.map((category, index) => {
                        return <div key={index} className="col-xxl-3 col-xl-3 col-md-6 col-sm-6">
                           <div className="category__item transition-3 text-center white-bg mb-30 wow fadeInUp" data-wow-delay=".3s" >
                              <div className="category__icon mb-25">
                                 <Link href="javascript:void(0)">
                                    <a onClick={handleClick}><img src={category.img} alt="" /></a>
                                 </Link>
                              </div>
                              <div className="category__content">
                                 <h3 className="category__title">
                                    <Link href="javascript:void(0)">
                                       <a onClick={handleClick}>{category.title}</a>
                                    </Link>
                                 </h3>
                                 <Link href="javascript:void(0)">
                                    <a className="link-btn">
                                       <i className="far fa-long-arrow-right"></i>
                                       Learn More
                                    </a>
                                 </Link>
                              </div>
                           </div>
                        </div>
                     })
                  }

               </div>
               {/* <div className="row">
                  <div className="col-xxl-12">
                     <div className="category__more mt-30 text-center">
                        <Link to="/product">
                           <a className="m-btn m-btn-2"> <span></span> View all categories</a>
                        </Link>
                     </div>
                  </div>
               </div> */}
            </div>
         </section>
      </>
   );
};

export default CategoryArea;